import {LocalDeliveryByZipCodeFlag, Payments, StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {ApiAddressWithGeoFragment, ExtendedFieldsFragment} from '../../gql/graphql';
import {ApiAddress} from '../../types/app.types';

export interface StoreCheckoutSettingsAndPayments {
  checkoutSettings: StoreCheckoutSettings;
  payments: Payments;
  localDeliveryByZipCodeFlag: LocalDeliveryByZipCodeFlag;
}

export interface UserFieldsNamespaces {
  _user_fields: any | null;
}

export type ExtendedFieldNamespaces = Pick<ExtendedFieldsFragment, 'namespaces'> | UserFieldsNamespaces;

export interface UserExtendedFields {
  namespaces?: ExtendedFieldNamespaces;
}

export function isExtendedFieldNamespacesIsUserFields(
  namespaces: ExtendedFieldNamespaces
): namespaces is UserFieldsNamespaces {
  return (namespaces as UserFieldsNamespaces)._user_fields !== undefined;
}

export function isAddressWithGeoCode(address: ApiAddress): address is ApiAddressWithGeoFragment {
  return (address as ApiAddressWithGeoFragment).geocode !== undefined;
}

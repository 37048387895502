import {DataExtensionsService, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsApi} from '../apis/CheckoutSettingsApi';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {CheckoutOrigin} from '../utils/bi.util';
import {FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER, SPECS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {isFormEmpty} from '@wix/form-viewer/controller';
import {GROO_MSID} from '../utils/groo.utils';

export const USER_DEFINED_FIELDS_NAMESPACE = '_user_fields';
export const FQDN = 'wix.ecom.*.checkout';
export class CheckoutSettingsService {
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsApi: CheckoutSettingsApi;
  private readonly dataExtensionsService: DataExtensionsService;
  public checkoutSettings!: CheckoutSettingsModel;
  public showCouponSP!: boolean;
  public showExpressCheckoutSP!: boolean;
  public checkoutComposerEnabled!: boolean;
  public isExtendedFieldsFormEmpty!: boolean;
  public isGroo!: boolean;
  private readonly flowAPI: ControllerFlowAPI;

  constructor({
    controllerConfig,
    siteStore,
    flowAPI,
    dataExtensionsService,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
    dataExtensionsService: DataExtensionsService;
  }) {
    this.controllerConfig = controllerConfig;
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.dataExtensionsService = dataExtensionsService;
    this.checkoutSettingsApi = new CheckoutSettingsApi({siteStore, origin: CheckoutOrigin.checkout});
  }

  public async fetchSiteSettings(): Promise<void> {
    const checkoutSettingsAndPayments = await this.checkoutSettingsApi.getCheckoutSettings();
    this.checkoutSettings = new CheckoutSettingsModel({
      checkoutSettingsAndPayments,
      taxName: this.siteStore.taxName,
    });
  }

  public isGrooSite(): boolean {
    return this.flowAPI.experiments.enabled(SPECS.SupportGrooLogic) && this.siteStore.msid === GROO_MSID;
  }

  public async fetchIsCheckoutComposerEnabled(): Promise<void> {
    const response = await this.controllerConfig.essentials.httpClient.get(
      '/_serverless/checkout-composer-experiment-api/isEnabled'
    );
    this.checkoutComposerEnabled = response.data as boolean;
  }

  public async fetchIsExtendedFieldsFormIsEmpty(): Promise<boolean> {
    const isEmptyForm = await isFormEmpty({
      formId: FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID,
      httpClient: this.flowAPI.essentials.httpClient,
    });
    const isEmpty = isEmptyForm ?? /* istanbul ignore next */ true;
    this.isExtendedFieldsFormEmpty = isEmpty;
    return isEmpty;
  }

  public async init(): Promise<void> {
    await Promise.all([this.fetchSiteSettings(), this.fetchIsCheckoutComposerEnabled()]);
    if (this.checkoutComposerEnabled) {
      await this.fetchIsExtendedFieldsFormIsEmpty();
      await this.dataExtensionsService.fetchDataExtensionsSchema();
    }

    this.showCouponSP = this.controllerConfig.config.style.styleParams.booleans.checkout_showCoupon !== false;
    this.showExpressCheckoutSP =
      this.controllerConfig.config.style.styleParams.booleans.checkout_showExpressCheckout !== false;
    this.isGroo = this.isGrooSite();
  }
}
